exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-affiliate-index-js": () => import("./../../../src/templates/affiliate/index.js" /* webpackChunkName: "component---src-templates-affiliate-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-chat-with-us-js": () => import("./../../../src/templates/chat-with-us.js" /* webpackChunkName: "component---src-templates-chat-with-us-js" */),
  "component---src-templates-comprehensive-js": () => import("./../../../src/templates/comprehensive.js" /* webpackChunkName: "component---src-templates-comprehensive-js" */),
  "component---src-templates-contact-form-page-js": () => import("./../../../src/templates/contactFormPage.js" /* webpackChunkName: "component---src-templates-contact-form-page-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-for-family-js": () => import("./../../../src/templates/for-family.js" /* webpackChunkName: "component---src-templates-for-family-js" */),
  "component---src-templates-get-a-quote-index-js": () => import("./../../../src/templates/get-a-quote/index.js" /* webpackChunkName: "component---src-templates-get-a-quote-index-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-maternity-js": () => import("./../../../src/templates/maternity.js" /* webpackChunkName: "component---src-templates-maternity-js" */),
  "component---src-templates-medicines-js": () => import("./../../../src/templates/medicines.js" /* webpackChunkName: "component---src-templates-medicines-js" */),
  "component---src-templates-member-perks-js": () => import("./../../../src/templates/member-perks.js" /* webpackChunkName: "component---src-templates-member-perks-js" */),
  "component---src-templates-mental-health-js": () => import("./../../../src/templates/mental-health.js" /* webpackChunkName: "component---src-templates-mental-health-js" */),
  "component---src-templates-partners-js": () => import("./../../../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-quotation-quotation-js": () => import("./../../../src/templates/quotation/quotation.js" /* webpackChunkName: "component---src-templates-quotation-quotation-js" */),
  "component---src-templates-quotation-search-js": () => import("./../../../src/templates/quotation/search.js" /* webpackChunkName: "component---src-templates-quotation-search-js" */),
  "component---src-templates-referral-js": () => import("./../../../src/templates/referral.js" /* webpackChunkName: "component---src-templates-referral-js" */),
  "component---src-templates-resource-hub-index-js": () => import("./../../../src/templates/resource-hub/index.js" /* webpackChunkName: "component---src-templates-resource-hub-index-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-review-index-js": () => import("./../../../src/templates/review/index.js" /* webpackChunkName: "component---src-templates-review-index-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-stand-alone-child-js": () => import("./../../../src/templates/stand-alone-child.js" /* webpackChunkName: "component---src-templates-stand-alone-child-js" */),
  "component---src-templates-tailwind-homepage-b-2-b-index-js": () => import("./../../../src/templates/tailwind/homepageB2b/index.js" /* webpackChunkName: "component---src-templates-tailwind-homepage-b-2-b-index-js" */),
  "component---src-templates-tailwind-hr-guide-index-js": () => import("./../../../src/templates/tailwind/hr-guide/index.js" /* webpackChunkName: "component---src-templates-tailwind-hr-guide-index-js" */),
  "component---src-templates-top-up-plans-js": () => import("./../../../src/templates/top-up-plans.js" /* webpackChunkName: "component---src-templates-top-up-plans-js" */),
  "component---src-templates-vhis-js": () => import("./../../../src/templates/vhis.js" /* webpackChunkName: "component---src-templates-vhis-js" */),
  "component---src-templates-women-js": () => import("./../../../src/templates/women.js" /* webpackChunkName: "component---src-templates-women-js" */),
  "component---src-templates-you-js": () => import("./../../../src/templates/you.js" /* webpackChunkName: "component---src-templates-you-js" */)
}

